(function ($) {
    $( "#js-range-slider" ).slider({
        value: 90,
        min: 5,
        max: 100,
        step: 5,
        slide: function( event, ui ) {
            $("#js-range-slider-amount").text(ui.value + "%");
            $("#js-range-slider-input").val(ui.value);
        }
    });

    $("#js-range-slider-input").val($("#js-range-slider").slider("value"));
})(jQuery);