/**
 * Funkcja odpowiada za wyswietlenie komunikatu na stronie.
 * 
 * @version 1.0.0
 */
(function ($) {

    // Vars
    var $message = $('#js-page-message'),
        $close = $('#js-page-message-btn-close'),
        messageClassClose = 'page-message--close',
        messageClassOpen = 'page-message--open';

    if (typeof Cookies.get('gen-gaz-energia-page-message-show') === 'undefined') {
        // Init message on load
        // window.onload = function () {
            $message.removeClass(messageClassClose);
            $message.addClass(messageClassOpen);
        // };
        
        // Click close
        $close.on('click', function () {
            $message.addClass(messageClassClose);
            setTimeout(function () {
                $message.hide();
            }, 300);

            Cookies.set('gen-gaz-energia-page-message-show', 'Cookies!', {
                expires: 1,
                secure: location.protocol == 'https:' ? true : false,
            });
        });
    }

})(jQuery);