(function ($) {

    // Vars
    var $accordionCard = $('.accordion-card'),
        accordionOpenClass = 'accordion-card--open',
        accordionCardChild = '.accordion-card--child';
    
    $(document).on('click', '.js-accordion-click', function () {
        var $this = $(this);

        if ($this.parent().hasClass(accordionOpenClass)) {
            $this.parent().toggleClass(accordionOpenClass);
        } else { 
            $accordionCard.removeClass(accordionOpenClass);
            $this.parent().toggleClass(accordionOpenClass);
        }
    });

    $(document).on('click', '.js-child-accordion-click', function () {
        var $this = $(this);

        if ($this.parent(accordionCardChild).hasClass(accordionOpenClass)) {
            $this.parent(accordionCardChild).toggleClass(accordionOpenClass);
        } else { 
            $this.parent(accordionCardChild).toggleClass(accordionOpenClass);
        }
    });

})(jQuery);