/**
 * Funkcja odpowiada za wyswietlenie komunikatu o ciasteczkach.
 * 
 * @version 1.1.0
 */
(function ($) {

    if (typeof Cookies.get('gen-cookies-accepted') === 'undefined') {
        $('#js-cookies').removeClass('hide');
        $('#js-accept-cookie').on('click', function (e) {
            e.preventDefault();
            Cookies.set('gen-cookies-accepted', 'Cookies!', {
                expires: 31,
                secure: location.protocol == 'https:' ? true : false,
            });

            // Hint from MAN BrandPortal
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'cookie-consent'
            });

            $('#js-cookies').addClass('hide');
        });

        $('#js-hide-cookie').on('click', function (e) {
            e.preventDefault();
            $('#js-cookies').addClass('hide');
        });
    }

})(jQuery);
