(function ($) {

    // Vars
    var $tabCard = $('.js-tab-item'),
        $tabClick = $('.js-tab-click');

    $tabClick.on('click', function (e) {
        e.preventDefault();
        
        setTimeout(function() {
            window.scrollTo(0, 0);
        }, 1);
        
        var $this = $(this),
            tabID = $this.data('id');

        // window.location.hash = tabID;
        
        $tabClick.removeClass('menu-tabs__tab-name--open');
        $this.toggleClass('menu-tabs__tab-name--open');

        $tabCard.removeClass('tabs__item--open');
        $('#' + tabID).toggleClass('tabs__item--open');
    });

    $(document).ready(function () {
        if (window.location.hash) { 
            setTimeout(function() {
                window.scrollTo(0, 0);
            }, 1);
            $tabClick.removeClass('menu-tabs__tab-name--open');
            $('[data-id=' + window.location.hash.substring(1) + ']').addClass('menu-tabs__tab-name--open');
            $tabCard.removeClass('tabs__item--open');
            $(window.location.hash).addClass('tabs__item--open');
        }
    });

})(jQuery);