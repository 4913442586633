(function ($) {

    // Vars
    var teamMemberDesc = '.js-team-member-description',
        teamMemberInfo = '.js-team-member-info',
        teamMemberOpenClass = 'team-member__description--open',
        $teamMemberClick = $('.js-team-member-more');
    
    $teamMemberClick.on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
            $parent = $this.parents(teamMemberInfo)

        $teamMemberClick.text('Pokaż Więcej');
        
        if ($parent.find(teamMemberDesc).hasClass(teamMemberOpenClass)) { 
            $this.text('Pokaż Więcej');
        } else { 
            $(teamMemberDesc).removeClass(teamMemberOpenClass);
            $this.text('Zwiń');
        }

        $parent.find(teamMemberDesc).toggleClass(teamMemberOpenClass);
    });

})(jQuery);