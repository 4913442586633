(function ($) {
    var $form = $('#js-questions-search-form'),
        $input = $('#js-questions-search-input'),
        $hints = $('#js-questions-search-hints')

    function initHints(e) {
        var that = this,
            input_text = $(that).val();

        /**
         * 
         */
        $(document).on('click', '.js-search-hints-item', function (e) {
            var hint = $(this).text();

            $input.val(hint);
            $hints.removeClass('active').html('')
        });

        /**
         * ukryj jeśli puste
         */
        if (input_text != '' && input_text.length > 2) {
            $hints.addClass('active')
        } else {
            $hints.removeClass('active').html('')
        }

        /**
         * pokaż/ukryj na blur
         */
        $input.focus(function () {
            input_text = $input.val();
            if (input_text != '' && input_text.length > 2) {
                $hints.addClass('active')
            }
        });

        $(document).on('click', function (e) {
            if ($(e.target).closest($hints).length === 0 && $(e.target).closest($form).length === 0 && $(e.target).closest($input).length === 0) {
                $hints.removeClass('active')
            }
        });

        $hints.addClass('pending')

        $.ajax({
            url: ajax.url,
            data: {
                action: 'search_hints',
                value: input_text,
            },
            type: 'post',
            beforeSend: function () {
                $('.search-hints__list')
                    .html('<div class="loader-container js-loader-container"><div class="loader"></div></div>')
                    .addClass('loading'); 
            },
            success: function (data) {
                $hints.removeClass('pending')
                data = JSON.parse(data)
                $hints.html(data.questions)
            }
        });

        return false
    }

    //init hints
    $input.on('keyup', initHints);

})(jQuery);
