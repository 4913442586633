/**
 * Funkcja odpowiada za wyswietlenie popup na stronie.
 * 
 * @version 1.0.0
 */
(function ($) {

    // Vars
    var popupClassClose = 'popup--close',
        popupClassOpen = 'popup--open';

    // Vars
    var $popup = $('#js-popup'),
        $close = $('#js-popup-close-button');

    if (typeof Cookies.get('gen-gaz-energia-popup-show') === 'undefined') {
         // Init popup on load
        // window.onload = function () {
            $popup.removeClass(popupClassClose);
            $popup.addClass(popupClassOpen);
        // };
        
        // Click close
        $close.on('click', function () {
            $popup.addClass(popupClassClose);
            setTimeout(function () {
                $popup.hide();
            }, 300);

            Cookies.set('gen-gaz-energia-popup-show', 'Cookies!', {
                expires: 1,
                secure: location.protocol == 'https:' ? true : false,
            });
        });
    }

    // Vars
    var $graphic_popup = $('#js-graphic-popup'),
        $graphic_close = $('#js-graphic-popup-close-button');

    if (typeof Cookies.get('gen-gaz-energia-graphic-popup-show') === 'undefined') {
         // Init popup on load
        // window.onload = function () {
            $graphic_popup.removeClass(popupClassClose);
            $graphic_popup.addClass(popupClassOpen);
        // };
        
        // Click close
        $graphic_close.on('click', function () {
            $graphic_popup.addClass(popupClassClose);
            setTimeout(function () {
                $graphic_popup.hide();
            }, 300);

            Cookies.set('gen-gaz-energia-graphic-popup-show', 'Cookies!', {
                expires: 1,
                secure: location.protocol == 'https:' ? true : false,
            });
        });
    }

})(jQuery);