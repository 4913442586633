/**
 * Funkcja odpowiada za wyswietlenie wyszukiwarki w menu.
 * 
 * @version 1.0.0
 */

(function ($) {

    var $searchLink = $('.js-link-search'),
        $searchInput = $('.js-header-search-input'),
        $searchClose = $('.js-header-search-close'),
        $search = $('.js-header-search');

    $searchLink.on('click', function (e) {
        e.preventDefault();
        
        // $searchInput.focus();
        $search.toggleClass('header-search--active');
    });

    $searchClose.on('click', function (e) {
        e.preventDefault();
        
        $search.removeClass('header-search--active');
        $searchInput.val('');
    });

    $(document).scroll(function () { 
        if ($(document).scrollTop() >= 80) {
            $search.removeClass('header-search--active');
            $searchInput.val('');
        }
    });

})(jQuery);