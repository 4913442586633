(function ($) {
    
    var $form = $('#js-posts-form');
    
    $(document).on('change', '.pagination__page-current', function (e) {
        e.preventDefault();
        $paged = $form.find('#js-post-paged').val($(this).val());

        $form.submit();
    });

})(jQuery);