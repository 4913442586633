(function ($) {

    /**
     *  Kalkulator zużycia energii
     **/
    var selectUnit = '#js-select-unit-energy',
        selectCity = '#js-select-energy-city',
        selectjsCommunity = '#js-select-energy-community',
        selectedCityVal = null,
        unitEnergy = '#js-unit-energy';
    
    var $gasCounter = $('#js-gas-counter'),
        $energyCalculate = $('#js-energy-calculate'),
        $energyResultM3 = $('#js-energy-result-m3'),
        $energyResultKwh = $('#js-energy-result-kwh'),
        $energyResultText = $('#js-energy-result-text'),
        $energyResultUnit = $('#js-energy-result-unit'),
        $energyResult = $('#js-energy-result');
    
    // $(document).ready(function () {
    //     _ajaxLoadCityCalculator();
    // });

    /**
     * Wybór jednostki
     **/
    $(selectUnit).on('change', function () {
        $(unitEnergy).text($(selectUnit + ' option:selected').val());

        if ($(selectUnit + ' option:selected').val() == 'm3') {
            $('.js-give-data-checkbox').show();

            $energyResultUnit.text('kWh/h');
        } else { 
            $energyResultUnit.text('m3');
        }
    });

    /**
     * Wybór miejscowości
     **/
    $(selectCity).on('change', function () {
        selectedCityID = $(selectCity + ' option:selected').data('id');
        selectedCityVal = $(selectCity + ' option:selected').data('rate');
    });

    if($(selectjsCommunity + ' option:selected').val() == null || $(selectjsCommunity + ' option:selected').val() == 'Wybierz...'){
        $(selectCity).on('change', function () {
            _ajaxLoadCommunityCalculator(selectedCityID);
        });
    }

    /**
     * Wartośc zużycie gazu
     **/
    $gasCounter.on('input', function() { 
        var value = $(this).val();
        $(this).val(inputValidation(value))
    });

    $('.js-give-data-checkbox').hide();
    $('.js-give-col').hide();
    $('.js-default-col').show();

    $('#js-give-data-energy-calculator').on('click', function () { 
        $("input").val("");

        if ($(this).prop('checked') == true) {
            $('.js-give-col').show();
            $('.js-default-col').hide();
        } else { 
            $('.js-give-col').hide();
            $('.js-default-col').show();
        }
    })
    var prev_state = '',
        current_state = '';
    
    $('#js-prev-state').on('input', function () {
        prev_state = $(this).val();
        $(this).val(inputValidation(prev_state))
    });

    $('#js-current-state').on('input', function () {
        current_state = $(this).val();
        $(this).val(inputValidation(current_state))
    });

    /**
     * Obliczania zużycia energii
     **/
    $energyCalculate.on('click', function (e) { 
        e.preventDefault();

        $gasCounter.removeClass('input--error');
  
        $('html, body').animate({
            scrollTop: $energyResult.offset().top - 150
        }, 400);

        if ($('#js-give-data-energy-calculator').prop('checked') == true) {

            if (prev_state === '' || prev_state == null) {
                alert('Podaj stany licznika.');
                return;
            }

            if (current_state === '' || current_state == null) {
                alert('Podaj stany licznika.');
                return;
            }

            if (prev_state > current_state) {
                alert('Stan licznika poprzedniego nie może być większy od stanu licznika bieżącego.');
                return;
            }

            $gasCounter.val(current_state - prev_state);
        }

        if (selectedCityVal == null || selectedCityVal == '') {
            alert('Wybierz miejscowość');
            return;
        }
        
        if ($gasCounter.val() == null || $gasCounter.val() == '') {
            $gasCounter.addClass('input--error');
            alert('Podaj zużycie gazu');
            return;
        }
        
        // selectedCityVal = selectedCityVal.replace(',', '.');

        var unit = $(selectUnit).val(),
            value = parseFloat($gasCounter.val().replace(',', '.')),
            cityValue = parseFloat(selectedCityVal);
        
        if (unit == '') {
            alert('Wybierz jednostkę przeliczania zużycia gazu');
        }else { 
            printEnergyResult(value, cityValue);
        }
    })

    /**
     * calculations = calculations.toFixed(1);
     * conversion = (calculations+"").replace(".",",");
     **/
    function printEnergyResult(value, cityValue) {
        resultM3 = value / cityValue;
        resultKWh = value * cityValue;

        conversionM3 = Math.round(resultM3).toFixed(1);
        conversionKWh = Math.round(resultKWh).toFixed(1);

        conversionM3 = numberWithSpaces(conversionM3).replace('.', ',');
        conversionKWh = numberWithSpaces(conversionKWh).replace('.', ',');

        $energyResultM3.html(conversionM3 + ' ' + 'm' + '<sup>' + '3' + '</sup>');
        $energyResultKwh.text(conversionKWh + ' ' + 'kWh');

        // $energyResultText
        if ($(selectUnit + ' option:selected').val() == 'm3') {
            $energyResultText.text(conversionKWh + ' ' + 'kWh');;
        } else { 
            $energyResultText.html(conversionM3 + ' ' + 'm' + '<sup>' + '3' + '</sup>');
        }
    }

    var community_id = null, // Gmina
        community_name = null; // Gmina

    $('#js-select-energy-community').on('change', function (e) {
        community_id = $('#js-select-energy-community option:selected').val();
        community_name = $('#js-select-energy-community option:selected').text();

        _ajaxLoadCityCalculator()
    });

    function _ajaxLoadCityCalculator() {
        $('.js-loader-energy-cities')
        .html('<div class="loader-container js-loader-container"><div class="loader is-small" style="top: 15px"></div></div>')
        .addClass('loading'); 

        $.ajax({
            url: ajax.url,
            data: {
                community_id: community_id,
                action: 'mda_ajax_cities',
            }, // form data
            type: "POST",
            success: function (data) {
                $('.js-loader-energy-cities').remove();
                $('#js-select-energy-city').html(data);
            }
        });
        return false;
    }

    function _ajaxLoadCommunityCalculator(id) {
        $.ajax({
            url: ajax.url,
            data: {
                city_id: id,
                action: 'mda_ajax_community',
            }, // form data
            type: "POST",
            success: function (data) {
                $('#js-select-energy-community').html(data)
            }
        });
        return false;
    }

    function numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    function inputValidation(value) { 
        var val = value.replace(/[^0-9.,]*/g, '');
        
        val = val.replace(/\.{2,}/g, '.');
        val = val.replace(/\.,/g, ',');
        val = val.replace(/\,\./g, ',');
        val = val.replace(/\,{2,}/g, ',');
        val = val.replace(/\.[0-9]+\./g, '.');

        return val;
    }
    
})(jQuery);