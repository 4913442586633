(function ($) {

    // Vars
    var $header = $('#js-header'),
        $headerScroll = $('#js-header-scroll'),
        headerClassShow = 'header-scroll--show',
        headerHeight = $header.outerHeight() * 2;
    
    // Check scrollTop
    $(window).scroll(function () {
        var wS = $(this).scrollTop();

        // Remove class scroll to header
        $headerScroll.removeClass(headerClassShow);

        // Add class scroll to header
        if (wS > headerHeight) {
            $headerScroll.addClass(headerClassShow);
        }
    });

    /**
     * Responsive menu
     */
    var $burger = $('.js-burger'),
        $collapse = $('#js-collapse');
    
    var item_parent = '.js-menu-item-parent',
        submenu = '.js-submenu';
    
    $burger.click(function () {
        $burger.toggleClass('burger-icon--open');
        $(submenu).removeClass('open');
        if ($collapse.hasClass('header-collapse--open')) {
            $collapse.addClass('header-collapse--closed');
            $('body').removeClass('no-scroll');

            setTimeout(function () {
                $collapse.removeClass('header-collapse--closed').removeClass('header-collapse--open');
            }, 250);

        } else {
            $collapse.addClass('header-collapse--opened');
            $('body').addClass('no-scroll');

            setTimeout(function () {
                $collapse.removeClass('header-collapse--opened').addClass('header-collapse--open');
            }, 250);

            $('html, body').animate({
                scrollTop: $('html, body').offset().top
            }, 250);
        }

        $(submenu).parent().addClass('js-menu-item-parent');
    });

    $(document).on('click', item_parent, function (e) { 
        e.preventDefault();

        var $this = $(this);
        
        $(submenu).removeClass('open');
        $this.find(submenu).toggleClass('open');
    })

    $(document).on('click', submenu, function (e) { 
        e.stopPropagation();
    })

    $(document).ready(function() {
        function scrollToHash(hash) {
            const targetElement = $(hash);
            if (targetElement.length) {
                $('html, body').animate({
                    scrollTop: targetElement.offset().top
                }, 600);
            }
        }
    
        $('.header-menu__link, .submenu-list__link').on('click', function(event) {
            const href = $(this).attr('href');
            const currentPath = window.location.pathname;
            const url = new URL(href, window.location.origin);
            const targetPath = url.pathname;
    
            if (href.startsWith('#')) {
                event.preventDefault();
                scrollToHash(href);
            } else if (targetPath === currentPath && url.hash) {
                event.preventDefault();
                scrollToHash(url.hash);
            }
        });
    
        if (window.location.hash) {
            scrollToHash(window.location.hash);
        }
    });

})(jQuery);