(function ($) {

    var sidebarHeader = '.js-page-sidebar-header',
        sidebarWidget = '.js-page-sidebar-widget';
    
    $(document).on('click', sidebarHeader, function () {
        var $this = $(this);
        
        if ($this.hasClass('active')) {
            $this.toggleClass('active');
            $(sidebarWidget).removeClass('show');
        } else { 
            $(sidebarHeader).removeClass('active');
            $this.toggleClass('active');
            $(sidebarWidget).addClass('show');
        }
    });
    
})(jQuery);