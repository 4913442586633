(function ($) {

    var send_mail = '';

    var $send_to = $('#js-cf-send-to'),
        $selected_topic = $('.js-cf-selected-topic'),
        $contract = $('.js-cf-contract-number'),
        select_topic = '.js-cf-select-topic',
        community_id = null, // Gmina
        $col = $('.js-cf-col-hide');

    $('#js-select-community,#js-cities-list').attr('required', false);
    $('#_js-select-community,#_js-cities-list').attr('required', false);
    $col.hide();
    $('.js-cf-col-hide-other').hide();

    /**
     * Ajax lista miejscowości przy wyborze gminy
     **/
    $('#js-select-community').on('change', function () {
        $('.js-cf-selected-community').val($('#js-select-community option:selected').val());
        community_id = $('#js-select-community option:selected').data('id');
        _ajaxLoadCity()
    });

    // do poprawy
    $('#_js-select-community').on('change', function () {
        $('.js-cf-selected-community').val($('#_js-select-community option:selected').val());
        community_id = $('#_js-select-community option:selected').data('id');
        _ajaxLoadCity()
    });

    /**
     *  Zmiana email do wysylki przy wyborze tematu rozmowy
    **/
    $(select_topic).on('change', function (e) {
        e.preventDefault();
        
        $contract.val('');
        $('#js-cities-list,#js-select-community').val(0).change();
        // do poprawy
        $('#_js-cities-list,#_js-select-community').val(0).change();

        send_mail = $(select_topic + ' option:selected').data('email');
        var topic_name = $(select_topic + ' option:selected').data('topic-name');

        $send_to.val(send_mail);
        $selected_topic.val(topic_name);

        /**
         *  Dodaj require * do "Numer umowy" 
         **/
        $contract.parent().find('.js-cf-label-required').removeClass('show');
        if (topic_name == 'update-contract' || topic_name == 'billing-payments') {
            $contract.parent().find('.js-cf-label-required').addClass('show');

        }

        /**
         *  Pokaż wybór gminy i miejscowości kiedy wybrano - Przyłączenie się do sieci gazowej
         **/
        $col.hide();
        if ($selected_topic.val() == 'connection') { 
            $col.show();
            $('#js-select-community,#js-cities-list').attr('required', true);
        }

        // do poprawy
        $('.js-cf-col-hide-other').hide();
        if ($selected_topic.val() == 'conclusion-contract' ||
            $selected_topic.val() == 'app' ||
            $selected_topic.val() == 'other' ||
            $selected_topic.val() == 'ebok') { 
            $('.js-cf-col-hide-other').show();
            $('#_js-select-community,#_js-cities-list').attr('required', true);
        }
           
        // $contract.on('keyup', function () {
        //     if ($selected_topic.val() == "billing-payments") {
        //         var input_val = $contract.val();
                
        //         /**
        //          *  Początek numeru umowy
        //          **/
        //         var contract_number_1 = ['1009', '1010', '1808', '1040', '1041', '1016', '1905', '1916', '1940', '1941'],
        //             contract_number_2 = ['1209', '1216', '1209', '1240', '1241'],
        //             contract_number_3 = ['1305', '1306', '1316', '1407', '1416', '1810', '1340', '1341', '1440', '1441'],
        //             contract_number_4 = ['1509', '1516', '1610', '1616', '1540', '1541', '1640', '1641', '1604'],
        //             contract_number_5 = ['1806', '1816', '1840', '1841'],
        //             contract_number_6 = ['1104', '1105', '1107', '1116', '1807', '1140'];
                
        //         /**
        //          *  Email
        //          **/
        //         var contact_mail_1 = 'Barbara.gniazdowska@gen.com.pl',
        //             contact_mail_2 = 'Marzena.blige@gen.com.pl',
        //             contact_mail_3 = 'Roma.konieczka@gen.com.pl',
        //             contact_mail_4 = 'Zuzanna.grzeszkowiak@gen.com.pl',
        //             contact_mail_5 = 'Anna.kosmacz@gen.com.pl',
        //             contact_mail_6 = 'Izabela.wojtowicz@gen.com.pl';
                
        //         /**
        //          *  Jeżeli numery umowy ma znaczenia z array
        //          **/
        //         if (jQuery.inArray(input_val.substr(0, 4), contract_number_1) !== -1) {
        //             $send_to.val(contact_mail_1);
        //         } else if (jQuery.inArray(input_val.substr(0, 4), contract_number_2) !== -1) {
        //             $send_to.val(contact_mail_2);
        //         } else if (jQuery.inArray(input_val.substr(0, 4), contract_number_3) !== -1) {
        //             $send_to.val(contact_mail_3);
        //         } else if (jQuery.inArray(input_val.substr(0, 4), contract_number_4) !== -1) {
        //             $send_to.val(contact_mail_4);
        //         } else if (jQuery.inArray(input_val.substr(0, 4), contract_number_5) !== -1) {
        //             $send_to.val(contact_mail_5);
        //         } else if (jQuery.inArray(input_val.substr(0, 4), contract_number_6) !== -1) {
        //             $send_to.val(contact_mail_6);
        //         } else {
        //             $send_to.val('rozliczenia@gen.com.pl');
        //         }
        //     }
        // });
    });

    /**
     *  Zmiana email przy wyborze miejscowości
     **/
    $('#js-cities-list').on('change', function (e) {
        $('.js-cf-selected-city').val($('#js-cities-list option:selected').val());
        send_mail = $('#js-cities-list option:selected').data('email');
        $send_to.val(send_mail);
    });

    // do poprawy
    $('#_js-cities-list').on('change', function (e) {
        $('.js-cf-selected-city').val($('#_js-cities-list option:selected').val());
    });

    /**
     * Ajax listy miejscowości
     **/
    function _ajaxLoadCity() {
        $.ajax({
            url: ajax.url,
            data: {
                community_id: community_id,
                action: 'mda_ajax_cities',
            }, // form data
            type: "POST",
            success: function (data) {
                $('#js-cities-list').html(data);
                 // do poprawy
                $('#_js-cities-list').html(data);
            }
        });
        return false;
    }

    /**
     * Rozwijanie klauzuli
     **/
    $(document).on('click', '.js-contact-form-expand-link', function (e) { 
        e.preventDefault();
        if ($('.js-contact-form-expand-list').hasClass('show')) {
            $('.js-contact-form-expand-list').removeClass('show');
            $(this).text('Rozwiń');
        } else { 
            $('.js-contact-form-expand-list').addClass('show');
            $(this).text('Zwiń');
        }
    })

})(jQuery);