(function ($) {
    $('.js-select').select2();
    $('.js-box-height').matchHeight();
    $('a[href$=".pdf"]').attr('download', '').attr('target', '_blank');


    $(".js-select-with-tags").select2({
        tags: true,
        placeholder: "Wybierz lub wpisz...",
    });
})(jQuery);