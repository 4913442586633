(function ($) {

    var office_id = null;

    // Vars
    var $popup = $('.js-office-locations-popup'),
        point = '.js-office-locations-point';
    
    $(document).on('click', point, function () {
        var $this = $(this);
        
        $(point).removeClass('office-locations__point--active');
        $this.toggleClass('office-locations__point--active');

        $popup.removeClass('office-locations__addres-popup--show');
        $('*[data-id="office-popup"]').toggleClass('office-locations__addres-popup--show');

        office_id = $(this).prop('id');

        $('#js-office-popup-content').html('<div class="loader-container"><div class="loader"></div></div>').addClass('loading');
        _ajaxLoad();
    });

    /**
     * Ajax
     **/
    function _ajaxLoad() {
        $.ajax({
            url: ajax.url,
            data: {
                office_id: office_id,
                action: 'mda_office',
            }, // form data
            type: "POST",
            success:function(data){
                $('#js-office-popup-content').html(data).removeClass('loading');
            }
        });
        return false;
    }

})(jQuery);