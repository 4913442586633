(function ($) {
    var pathname = '/kontakt';
    $(window).on("load", function () {
        if (window.location.pathname == pathname) {
            if (window.location.hash) {
                var sectionScroll = window.location.hash.substring(1);
                $('html, body').animate({
                    scrollTop: $("#"+sectionScroll).offset().top - 80
                }, 400);
            }
        }
    }); 
})(jQuery);