(function ($) {
    var invoice = '.js-invoice';

    var pin = '.js-invoice-pin',
        pinID = '',
        pin_position = '',
        tooltip_text = '';
    
    $(document).on('click', pin, function (e) {
        var $this = $(this);

        pin_position = $this.position();
        pinID = $this.attr('id');
        tooltip_text = $this.parent().data('text');

        if ($this.parent().hasClass('is-active')) {
            $this.parent().addClass('is-active');
        } else { 
            $(pin).parent().removeClass('is-active');
            $this.parent().addClass('is-active');
        }

        _ajaxInvoiceTooltip();
    });

    $(document).on('click', '.js-tab-invoice-click', function () { 
        $(pin).parent().removeClass('is-active');
        $('.js-invoice-tooltip').removeClass('is-active');
                 
    })
    
    $(document).on('click', function(e) {
        if ($(invoice).hasClass('is-open')) {
            if ( $(e.target).is(invoice) === false &&
                $(e.target).is('.js-invoice-hint') === false &&
                $(e.target).is('.js-invoice-pin') === false &&
                $(e.target).is('.js-invoice-icon') === false ) {
                    $(invoice+'.is-open').unwrap();
                    $(invoice).removeClass('is-open');
            }
        }
    });
    
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === false ) {
        openInvoicePopup()
    }

    function openInvoicePopup() { 

        $(invoice).on('click', function (e) {
            var target = $(e.target),
                $this = $(this);   

            if (target.is('.js-invoice-hint') || target.is('.js-invoice-pin') || target.is('.js-invoice-icon')) 
                return
            
            if ($this.hasClass('is-open')) {
                $this.unwrap();
            }

            $this.wrap('<div class="invoice-popup-wrapp"></div>')
            $this.addClass('is-open');
            $(pin).parent().removeClass('is-active');
            $('.js-invoice-tooltip').removeClass('is-active');
    
            e.stopPropagation()
        });
    }
    
    /**
     * Ajax
     **/
    function _ajaxInvoiceTooltip() {
        $.ajax({
            url: ajax.url,
            data: {
                tooltip_text: tooltip_text,
                action: 'mda_invoice_tooltip',
            }, // form data
            type: "POST",
            beforeSend: function () {
                $('.js-invoice-tooltip')
                    .html('<div class="loader-container js-loader-container"><div class="loader is-small is-light"></div></div>')
                    .addClass('is-active').css('top', pin_position.top).css('left', pin_position.left)
                    .addClass('loading'); 
            },
            success: function (data) {
                $('.js-invoice-tooltip').html(data);
            }
        });
        return false;
    }
    
})(jQuery);