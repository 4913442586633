(function ($) {

    var link = '.js-collapse-link',
        menu = '.js-collapse-menu';

    $(document).on('click', link, function (e) {
        e.preventDefault();
        var $this = $(this);
        
        $(menu).removeClass('show');

        if ($this.hasClass('active')) {
            $this.toggleClass('active');
        } else { 
            $(link).removeClass('active');
            $this.toggleClass('active');
            $this.next().addClass('show');
        }
    });

    var active_link = '.js-widget-collapse-active-link',
        widget_collapse = '.js-widget-collapse';

    $(document).ready(function () {
        if ($(active_link).hasClass('is-active')) { 
            $(active_link + '.is-active').parents(widget_collapse).find(link).addClass('active');
            $(active_link + '.is-active').parents(widget_collapse).find(menu).addClass('show');
        }
    });
    
})(jQuery);