(function ($) {
    $('.js-question-click').one('click', function () {
        var $this = $(this);
        var post_id = $this.attr('data-id');

        $.ajax({
            url: ajax.url,
            data: {
                post_id: post_id,
                action: 'mda_question_popular',
            }, // form data
            type: "POST",
            success: function (data) {
                console.log('success')
            }
        });
    });
})(jQuery);