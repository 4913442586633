(function ($) {
    var $officeMap = $('.office-map');
    var custom_marker = $('#js-map-geo-marker').val();
    var geo_h = $('#js-map-geo-h').val();
        geo_v = $('#js-map-geo-v').val();

    $officeMap.on( "mousewheel", function(e) {
        e.preventDefault();
    });    

    map = new OpenLayers.Map("office-map");
    map.addLayer(new OpenLayers.Layer.OSM(
        "OpenStreetMap", 
        [
            'https://a.tile.openstreetmap.org/${z}/${x}/${y}.png',
            'https://b.tile.openstreetmap.org/${z}/${x}/${y}.png',
            'https://c.tile.openstreetmap.org/${z}/${x}/${y}.png'
        ], 
        null
    ));
    var lonLat = new OpenLayers.LonLat( geo_h, geo_v )
        .transform(
            new OpenLayers.Projection("EPSG:4326"),
            map.getProjectionObject()
        );
    var zoom = 18;
    var markers = new OpenLayers.Layer.Markers( "Markers" );
    map.addLayer(markers);
    var size = new OpenLayers.Size(72,83);
    var icon = new OpenLayers.Icon(custom_marker, size);
    markers.addMarker(new OpenLayers.Marker(lonLat,icon));
    markers.addMarker(new OpenLayers.Marker(lonLat,icon.clone()));
    map.setCenter (lonLat, zoom);
})(jQuery);