(function ($) {

    /**
     *  Kalkulator Kalkulator mocy umownej
     **/
    var selectCity = '#js-select-power-city',
        selectjsCommunity = '#js-select-power-community',
        selectedCityVal = null,
        selectedHeaterVal = null,
        selectedCauldron2Val = null,
        selectedCauldron1Val = null;

    var $otherPower = $("#js-devices-total-power"),
        $heater = $('#js-heater'),
        $cauldron1 = $('#js-cauldron-1'),
        $cauldron2 = $('#js-cauldron-2'),
        $stove = $('#js-stove');
    
    var $powerResult = $('#js-power-result'),
        $powerResultM3 = $('#js-power-result-m3'),
        $powerResultKwh = $('#js-power-result-kwh'),
        $powerResultDesc = $('#js-power-result-opis');
    
    /**
     * Wybór miejscowości
     **/
    // $(document).ready(function () {
    //     _ajaxLoadCityCalculator();
    // });

    // $(selectCity).on('select2:open', function (e) {
    //     _ajaxLoadCityCalculator();
    // });

    $(selectCity).on('change', function () {
        selectedCityID = $(selectCity + ' option:selected').data('id');
        selectedCityVal = $(selectCity + ' option:selected').val();
        selectedCs = $(selectCity + ' option:selected').data('cs');
        selectedWopal = $(selectCity + ' option:selected').data('wopal');
    });

    if($(selectjsCommunity + ' option:selected').val() == null || $(selectjsCommunity + ' option:selected').val() == 'Wybierz...'){
        $(selectCity).on('change', function () {
            _ajaxLoadCommunityCalculator(selectedCityID);
        });
    }

    $('#js-heater-select-power').on('change', function () {
        selectedHeaterVal = $('#js-heater-select-power option:selected').val();
    });

    $('#js-cauldron-2-select-power').on('change', function () {
        selectedCauldron2Val = $('#js-cauldron-2-select-power option:selected').val();
    });

    $('#js-cauldron-1-select-power').on('change', function () {
        selectedCauldron1Val = $('#js-cauldron-1-select-power option:selected').val();
    });

    $otherPower.on('input', function() { 
        var value = $(this).val();
        $(this).val(inputValidation(value))
    });

    $stove.on('input', function() {
        var value = $(this).val();
        $(this).val(inputValidation(value))
    });
                      
    $cauldron1.on('input', function() { 
        var value = $(this).val();
        $(this).val(inputValidation(value))
    });

    $cauldron2.on('input', function() { 
        var value = $(this).val();
        $(this).val(inputValidation(value))
    });
    
    $heater.on('input', function() { 
        var value = $(this).val();
        $(this).val(inputValidation(value))
    });

    $("#js-power-calculate").click(function (e) {
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $powerResult.offset().top - 150
        }, 400);

        var efficiency = $('#js-range-slider-input').val();
        var n, error;
        var sumDevices, conversion1, conversion2;
        var result1, result2;
        var device1, device2, device3, device4, device5;

        if (selectedCityVal == null || selectedCityVal == '') {
            alert('Wybierz miejscowość');
            return;
        }

        if (efficiency == null || efficiency == '') {
            alert('Wybierz sprawność kotła.');
            return;
        }

        n = (efficiency / 100); 
        
        if (($stove.val()) > 0) {
            device1 = $stove.val() * 9
        } else {
            device1 = 0;
        }

        if (($cauldron1.val()) > 0) {
            device2 = $cauldron1.val() * selectedCauldron1Val
        } else {
            device2 = 0;
        }

        if (($cauldron2.val()) > 0) {
            device3 = $cauldron2.val() * selectedCauldron2Val
        } else {
            device3 = 0;
        }

        if ($heater.val() > 0) {
            device4 = $heater.val() * selectedHeaterVal
        } else {
            device4 = 0;
        }

        if ($otherPower.val() != '') {
            device5 = $otherPower.val().replace(",", ".");
        } else {
            device5 = 0;
        }

        error = 0;

        if (device5 != '') {

            if (($otherPower.val().search(/^[0-9]+[.,][0-9]+$/) == -1) && ($otherPower.val().search(/^[0-9]+$/) == -1) && ($otherPower.val().search(/^[-][0-9]+$/) == -1)) {
                
                alert('Sprawdź poprawność wartości - Inne deviceądenia o łącznej mocy.');

                $powerResultM3.text('');
                $powerResultKwh.text('');
                $powerResultDesc.text('');
                $otherPower.focus();

                error = 1;

            } else {
                sumDevices = device1 + device2 + device3 + device4 + parseFloat(device5);
            }

        } else {

            sumDevices = device1 + device2 + device3 + device4 + parseFloat(device5);

        }

        if (error == 0) {

            result1 = (sumDevices * 3.6) / (n * selectedWopal);
            result1 = result1.toFixed(2);
            result2 = result1 * (selectedCs / 3.6);

            result2 = result2.toFixed(2);
            conversion1 = (result1 + "").replace(".", ",");
            conversion2 = (result2 + "").replace(".", ",");

            $powerResultKwh.text(conversion2 + ' ' + 'kWh/h');
            $powerResultM3.html(conversion1 + ' ' + 'm' + '<sup>' + '3' + '</sup>' + '/h');

            if (result2 > 110) {

                $powerResultDesc.text('Jesteś Odbiorcą odbierającym powyżej 110kWh/h.')

            } else {

                $powerResultDesc.text('Jesteś Odbiorcą odbierającym do 110kWh/h.')

            }
        }

    })
    
    var community_id = null, // Gmina
        community_name = null; // Gmina

    $('#js-select-power-community').on('change', function (e) {
        community_id = $('#js-select-power-community option:selected').val();
        community_name = $('#js-select-power-community option:selected').text();

        _ajaxLoadCityCalculator()
    });

    function _ajaxLoadCityCalculator() {
        $('.js-loader-power-cities')
        .html('<div class="loader-container js-loader-container"><div class="loader is-small" style="top: 15px"></div></div>')
        .addClass('loading'); 
        
        $.ajax({
            url: ajax.url,
            data: {
                community_id: community_id,
                action: 'mda_ajax_cities',
            }, // form data
            type: "POST",
            success: function (data) {
                $('.js-loader-power-cities').remove();
                $('#js-select-power-city').html(data);
            }
        });
        return false;
    }

    function _ajaxLoadCommunityCalculator(id) {
        $.ajax({
            url: ajax.url,
            data: {
                city_id: id,
                action: 'mda_ajax_community',
            }, // form data
            type: "POST",
            success: function (data) {
                $('#js-select-power-community').html(data);
            }
        });
        return false;
    }

    function inputValidation(value) { 
        var val = value.replace(/[^0-9.,]*/g, '');
        
        val = val.replace(/\.{2,}/g, '.');
        val = val.replace(/\.,/g, ',');
        val = val.replace(/\,\./g, ',');
        val = val.replace(/\,{2,}/g, ',');
        val = val.replace(/\.[0-9]+\./g, '.');

        return val;
    }

})(jQuery);