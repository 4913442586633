(function ($) {
    /**
     * Mapster vars
     **/
    var basic_opts_mapster = '',
        initial_opts_mapster = '',
        $area = $('area');
    
    /**
     * Other vars
     **/
    var voivodeship_id = null, // Województwo
        voivodeship_name = null, // Województwo
        county_id = null, // Powiat
        county_name = null, // Powiat
        community_id = null, // Gmina
        community_name = null, // Gmina
        city_search = null; // Miejscowość
    
    $(document).ready(function () {
        _mapsterInit();
        // $area.mapster('select');
        $('#js-distribution-list-section').hide();
    });
       
    $(document).on('click', '.js-distribution-button', function (e) {
        e.preventDefault();
        var $this = $(this);

        $this.parents('.js-distribution-list-tr').find('.js-distribution-list-collapse')
            .removeClass('distribution-list__collapse--hide')
            .toggleClass('distribution-list__collapse--show');
        
        $this.parents('.js-distribution-list-tr').toggleClass('distribution-list__tr--open');
    });

    var select_voivodeships = '.js-select-voivodeships',
        select_county = '.js-select-county',
        select_community = '.js-select-community',
        select_city = '.js-select-city';
    

    $(select_voivodeships).on('change', function (e) {
        voivodeship_id = $(select_voivodeships + ' option:selected').val();
        voivodeship_name = $(select_voivodeships + ' option:selected').text();
        
        county_id = null;
        community_id = null;
        
        $(select_county)
            .empty()
            .append('<option selected>Wybierz...</option>');

        $(select_community)
            .empty()
            .append('<option selected>Wybierz...</option>');
        
        $(select_city)
            .empty()
            .append('<option selected>Wybierz...</option>');

        _ajaxLoadCounty(voivodeship_id, community_id);
        _ajaxLoadMap(voivodeship_id);

        return false;
    });

    $(select_county).on('change', function (e) {
        county_id = $(select_county + ' option:selected').val();
        county_name = $(select_county + ' option:selected').text();

        community_id = null;

        if (county_id != null || county_id != '') {
            $('[data-id=' + county_id + ']').mapster('select');
        }

        $(select_city)
            .empty()
            .append('<option selected>Wybierz...</option>');

        _ajaxLoadCommunity(county_id);
    });

    $(select_community).on('change', function () {
        community_id = $(select_community + ' option:selected').val();
        community_name = $(select_community + ' option:selected').text();

        _ajaxLoadCity()
    });

    $('.js-city-search').on('change', function (e) {
        city_search = $(this).val();
    });

    /**
     * Ajax
     **/
    function _ajaxLoadCounty(id, county_id) {
        $.ajax({
            url: ajax.url,
            data: {
                voivodeship_id: id,
                action: 'mda_distribution_county',
            }, // form data
            type: "POST",
            success: function (data) {
                data = JSON.parse(data)

                $(select_county).html(data.terms);
                $(select_county).val(county_id);
                $(select_county).select2().trigger('select');
            }
        });
        return false;
    }

    function _ajaxLoadCommunity(id) {
        $.ajax({
            url: ajax.url,
            data: {
                county_id: id,
                action: 'mda_distribution_community',
            }, // form data
            type: "POST",
            success: function (data) {
                data = JSON.parse(data)
                $('.js-select-community').html(data.terms)
            }
        });
        return false;
    }

    function _ajaxLoadCity() {
        $.ajax({
            url: ajax.url,
            data: {
                voivodeship_id: voivodeship_id,
                county_id: county_id,
                community_id: community_id,
                action: 'mda_distribution_posts',
            }, // form data
            type: "POST",
            success: function (data) {
                $('.js-select-city').html(data);
            }
        });
        return false;
    }

    /**
     *  Mapster
     **/
    $(document).on('click', '.js-map-county', function () {
        community_id = null;
        community_name = $(this).data('state');
        county_id = $(this).data('id');

        $(select_county).val(county_id);
        $(select_county).select2().trigger('select');
        $(select_community).empty().append('<option selected>Wybierz...</option>');

        _ajaxLoadCounty(voivodeship_id, county_id);
        _ajaxLoadCommunity(county_id);
        _ajaxLoadCountyMap(county_id);
        return false;
    })

    $(document).on('click', '.js-map-voivodeship', function () {
        voivodeship_id = $(this).data('id');

        $(select_voivodeships).val(voivodeship_id);
        $(select_voivodeships).select2().trigger('select');

        _ajaxLoadCounty(voivodeship_id);
        _ajaxLoadMap(voivodeship_id);
        return false;
    });

    /***
     * todo
     */
    function _ajaxLoadMap(id){
        $.ajax({
            url: ajax.url,
            data: {
                voivodeship_id: id,
                action: 'mda_mapster',
            }, // form data
            type: "POST",
            dataType: 'json',
            beforeSend: function () {
                $poland.hide();
                $('[data-id]').addClass('d-none');
                $('#js-loader-init').show()
                    .html('<div class="loader-container js-loader-container"><div class="loader"></div></div>')
                    .addClass('loading'); 
            },
            success: function (data) {

                $('#js-loader-init').hide();
                $('[data-id="' + voivodeship_id + '"]').removeClass('d-none');

                data.forEach(function(element) {
                    $('#distribution-'+voivodeship_id).append('<area class="js-map-county" data-id="' + element[1] + '" shape="poly" data-state="Powiat ' + element[0] + '" coords="' + element[2] + '" href="#' + element[0] + '" />');
                });
                
                _mapsterInit();
                $('.js-distribution-voivodeship-mapster').mapster(initial_opts_mapster);
            }
        });
    }

    /***
    * load additional map
    */
    function _ajaxLoadCountyMap(id){
        $.ajax({
            url: ajax.url,
            data: {
                county_id: id,
                action: 'mda_mapster',
            }, // form data
            type: "POST",
            dataType: 'json',
            beforeSend: function () {
                $poland.hide();
                $('[data-id]').addClass('d-none');
                $('#js-loader-init').show()
                    .html('<div class="loader-container js-loader-container"><div class="loader"></div></div>')
                    .addClass('loading'); 
            },
            success: function (data) {
                $('#js-loader-init').hide();
                $('[data-id="' + county_id + '"]').removeClass('d-none');

                data.forEach(function(element) {
                    $('#distribution-'+county_id).append('<area class="js-map-county" data-id="' + element[1] + '" shape="poly" data-state="Powiat ' + element[0] + '" coords="' + element[2] + '" href="#' + element[0] + '" />');
                });
                
                _mapsterInit();
                $('.js-distribution-voivodeship-mapster').mapster(initial_opts_mapster);
            }
        });
    }

    /***
    * load tooltip city
    */

    $('.distribution-map__point').on('click', function() {
        var cityId = $(this).data('city-id');
        var $tooltip = $(this).next('.distribution-map__point-tooltip');
        var $point = $(this);

        if ($tooltip.is(':visible')) {
            $tooltip.hide();
            return;
        }

        $('.distribution-map__point-tooltip').not($tooltip).hide();

        if (!$tooltip.is(':visible') && $tooltip.data('loaded')) {
            $tooltip.show();
            return;
        }

        if (!$tooltip.data('loaded')) {
            $.ajax({
                url: ajax.url,
                type: 'GET',
                data: {
                    action: 'get_city_data',
                    city_id: cityId
                },
                success: function(response) {
                    var data = JSON.parse(response);

                    $tooltip.html(
                        '<p class="distribution-map__point-tooltip--title">' + data.title + '</p>' +

                        '<p class="distribution-map__point-tooltip--headline">Rodzaj gazu</p>' +
                        '<p class="distribution-map__point-tooltip--text">' + data.type_of_gas + '</p>' +
                        '<p class="distribution-map__point-tooltip--headline">Stopień gazyfikacji</p>' +
                        '<p class="distribution-map__point-tooltip--text">' + data.gasification + '</p>' +
                        '<p class="distribution-map__point-tooltip--headline">PUnkt wejścia do systemu</p>' +
                        '<p class="distribution-map__point-tooltip--text">' + data.point_distribution_system + '</p>'
                    );
                    $tooltip.data('loaded', true);

                                        var pointPosition = $point.position();
                                        $tooltip.css({
                                            top: pointPosition.top - $tooltip.outerHeight() - 17,
                                            left: pointPosition.left + ($point.outerWidth() / 2) - ($tooltip.outerWidth() / 2) - 1
                                        });
                                        $tooltip.show();
                },
                error: function(xhr, status, error) {
                    console.error('AJAX Error:', status, error);
                    $tooltip.html('Błąd w pobieraniu danych.');
                }
            });
        }
    });

    /**
     * Ajax load post
     **/
    $(document).on('submit', '#js-distribution-posts-form', function(e){
        e.preventDefault();

        $('#js-distribution-list-section').show();

        // $('html, body').animate({
        //     scrollTop: $("#js-distribution-list-section").offset().top - 100
        // }, 400);

        var posts_per_page = -1;

        // if(community_id){
        //     posts_per_page = '-1';
        // }

        $.ajax({
            url: ajax.url,
            data: {
                voivodeship_id: voivodeship_id,
                county_id: county_id,
                community_id: community_id,
                city_search: city_search,
                posts_per_page: posts_per_page,
                action: 'mda_distribution_posts',
            }, // form data
            type: "POST",
            dataType: "json",
            beforeSend: function () {
                $('#js-loader-init').show()
                    .html('<div class="loader-container js-loader-container"><div class="loader"></div></div>')
                    .addClass('loading'); 

                // $('#js-distribution-posts-loop')
                //     .html('<div class="loader-container js-loader-container"><div class="loader"></div></div>')
                //     .addClass('loading'); 
            },
            success: function (data) {

                if (data.status === 'error') {
                    $('.js-text-alert').text('Miejscowość nie została znaleziona w bazie.').removeClass('d-none');
                    $('#js-distribution-posts-loop').html('');
                    $('#js-distribution-location').hide();
                    $('#js-loader-init').hide();
                    return;
                } else {
                    $('#js-loader-init').hide();
                    $('.js-text-alert').addClass('d-none');
                }

                $('#js-distribution-location').show();

                if (community_name != null) {
                    $('#js-distribution-location').text(community_name);
                }
                
                if (community_name === 0) { 
                    $('#js-distribution-location').hide();
                }
                
                $('#js-distribution-posts-loop').html(data.data.html);

                var cityId = data.data.city_id;
                if (cityId) {
                    getCityParent(cityId, function(parentId) {
                        _ajaxLoadCountyMap(parentId);
                        $('[data-id="' + parentId + '"]').removeClass('d-none');

                        openTooltip(cityId);
                    });
                } else {
                    console.log("City ID not found in response");
                }
            },
            error: function (error) {
                console.log(error)
            }
        });
    });

// Funkcja do odpalania odpowiedniego tooltipa
function openTooltip(cityId) {
    var $tooltip = $('[data-city-id="' + cityId + '"]').next('.distribution-map__point-tooltip');
    var $point = $('[data-city-id="' + cityId + '"]');

    if ($tooltip.is(':visible')) {
        $tooltip.hide();
        return;
    }

    $('.distribution-map__point-tooltip').not($tooltip).hide();

    if (!$tooltip.is(':visible') && $tooltip.data('loaded')) {
        $tooltip.show();
        return;
    }

    if (!$tooltip.data('loaded')) {
        $.ajax({
            url: ajax.url,
            type: 'GET',
            data: {
                action: 'get_city_data',
                city_id: cityId
            },
            beforeSend: function () {
                $('#js-loader-init').show()
                    .html('<div class="loader-container js-loader-container"><div class="loader"></div></div>')
                    .addClass('loading'); 
            },
            success: function(response) {
                $('#js-loader-init').hide();

                var data = JSON.parse(response);

                $tooltip.html(
                    '<p class="distribution-map__point-tooltip--title">' + data.title + '</p>' +
                    '<p class="distribution-map__point-tooltip--headline">Rodzaj gazu</p>' +
                    '<p class="distribution-map__point-tooltip--text">' + data.type_of_gas + '</p>' +
                    '<p class="distribution-map__point-tooltip--headline">Stopień gazyfikacji</p>' +
                    '<p class="distribution-map__point-tooltip--text">' + data.gasification + '</p>' +
                    '<p class="distribution-map__point-tooltip--headline">PUnkt wejścia do systemu</p>' +
                    '<p class="distribution-map__point-tooltip--text">' + data.point_distribution_system + '</p>'
                );
                $tooltip.data('loaded', true);

                var pointPosition = $point.position();
                $tooltip.css({
                    top: pointPosition.top - $tooltip.outerHeight() - 17,
                    left: pointPosition.left + ($point.outerWidth() / 2) - ($tooltip.outerWidth() / 2) - 1
                });
                $tooltip.show();
            },
            error: function(xhr, status, error) {
                console.error('AJAX Error:', status, error);
                $tooltip.html('Błąd w pobieraniu danych.');
            }
        });
    }
    $(document).on('click', function(event) {
        if (!$(event.target).closest('.distribution-map__point-tooltip, [data-city-id="' + cityId + '"]').length) {
            $tooltip.hide();
        }
    });
}

// Zapobieganie dodawaniu wielokrotnych listenerów
$(document).off('click', '.distribution-map__point', function() {
    var cityId = $(this).data('city-id');
    openTooltip(cityId);
});

$(document).on('click', '.distribution-map__point', function() {
    var cityId = $(this).data('city-id');
    openTooltip(cityId);
});

/**
 * Funkcja do pobrania ID rodzica miasta
 */
function getCityParent(cityId, callback) {
    $.ajax({
        url: ajax.url,
        data: {
            city_id: cityId,
            action: 'mda_get_parent',
        },
        type: "POST",
        success: function (response) {
            if (response.success) {
                var parentId = response.data.sub_category;
                if (callback) callback(parentId);
            } else {
                console.log("Error fetching city parent:", response.data);
            }
        },
        error: function (error) {
            console.log(error);
        }
    });
}


    /**
     * Mapster Init
     **/
    function _mapsterInit() { 
        $poland = $('#js-poland-map');
        $area = $('area');
        $tooltip = $('#js-distribution-map-tooltip');
        basic_opts_mapster = {
            mapKey: 'data-state'
        };

        initial_opts_mapster = $.extend({}, basic_opts_mapster, { 
            fill: true,
            fillColor: '000000',
            fillOpacity: 1,
            singleSelect: true,
            render_highlight: {
                fillColor: 'FFDD00',
                stroke: false,
                strokeWidth: 1,
                strokeColor: 'AAAAAA',
            },
            render_select: {
                fillColor: 'FFDD00',
                stroke: false,
                strokeWidth: 1,
                strokeColor: 'AAAAAA',
            },
            fadeInterval: 50,
        });

        $('#js-poland-mapster').mapster(initial_opts_mapster);
        
        $area.on('mousemove', function (e) { 
            e.preventDefault();
            var state = $(this).data('state');
            
            var pos = $(this).offset();
            var elem_left = pos.left;
            var elem_top = pos.top;
            
            var Xinner = e.pageX - elem_left - 120;
            var Yinner = e.pageY - elem_top - 120;
            
            $tooltip.addClass('active').text(state).css('transform', 'translateY(' + Yinner + 'px) translateX(' + Xinner + 'px)');
        });

        $area.on('mouseout', function () { 
            $tooltip.removeClass('active')
        });
    }

    //Reset mapy

    $('#reset-map').on('click', function() {
        $('#js-loader-init').hide();
        $('.loader-container').remove();
        
        $poland.show();
        
        $('[data-id]').addClass('d-none');
        
        $('[id^="distribution-"]').empty();
        
        $('.js-distribution-voivodeship-mapster').mapster('rebind', initial_opts_mapster);
        
        $('#js-distribution-posts-form')[0].reset(); // Resetuje formularz do domyślnych wartości
        $('#js-distribution-list-section').hide(); // Ukrywa sekcję z listą wyników
        $('#js-distribution-posts-loop').html(''); // Czyści zawartość pętli postów
        $('#js-distribution-location').hide(); // Ukrywa lokalizację
        $('.js-text-alert').addClass('d-none');

        voivodeship_id = null;
        county_id = null;
        community_id = null;
        city_search = '';
        community_name = null;

        $(select_county).select2().trigger('change');
    });
})(jQuery);